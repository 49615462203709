import React from 'react'

import TemplateController from '../category-page-template/template-controller'
import ProductsView from './products-view'

const ProductsController = ({ pageContext, location }) => {
  const originArticles = pageContext.articles
  const viewProps = TemplateController({
    data: { articles: { edges: originArticles } },
    location,
    slug: 'product-news',
  })

  return <ProductsView {...viewProps} />
}

export default ProductsController
