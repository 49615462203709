import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { eventsLanguages } from '@/i18n/config'
import { getBrowserPrefLang } from '@/modules/utils'

import ProductsController from '../screens/products'

const Products = (props) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    const allAvaLangs = eventsLanguages.map((lang) => lang.code)
    const lang = getBrowserPrefLang(allAvaLangs)
    if (lang && lang !== i18n.language) {
      const path =
        eventsLanguages.find((item) => item.code === lang)?.path ?? ''
      navigate(`/product-news${path}`)
    }
  }, [i18n.language])
  return <ProductsController {...props} />
}

export default Products
